import { brandName } from '@helpers/constants';
import routes from './routes';
// use '/[pages route]' for pages and #[componentName] for components.
export const HeadTagData = {
    '/about-us': {
        title: `About Us | ${brandName}`,
        metaTitle: `About Us | ${brandName}`,
        metaDesc: `${brandName} offers retail investors great alternate fixed income investment opportunities for wealth creation beyond the traditional asset classes all via an easy-to-use convenient digital platform. It is built by experienced management team with 100+ years of experience and backed by marquee investors.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/about-us`,
    },
    '/dashboard': {
        title: `Dashboard | ${brandName}`,
        metaDesc: `All your investments at one place on ${brandName} wealth platform! View your complete portfolio of fixed income instruments with ${brandName}.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/opportunities': {
        title: `Investment Opportunities | ${brandName}`,
        metaDesc: `Explore fixed income investment opportunities on ${brandName} that offers returns up to 20% IRR. Discover a world of high-yield fixed income investing.`,
        metaKeywords:
            'Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/': {
        canonicalUrl: process.env.NEXT_PUBLIC_APP_URL,
        metaDesc: `Discover bonds and other fixed-income investments with ${brandName}. Invest in listed assets like bonds, G-secs, SDLs, T-bills, and SGBs today.`,
        title: `Listed & Secured Bond Investments | Online Bond Platform | ${brandName}`,
        metaTitle: `Listed & Secured Bond Investments | Online Bond Platform | ${brandName}`,
        metaKeywords:
            'Regulated Bond Investment, Online Bond Platform, Fixed Income Investments, High Return Investments, Low-risk Investments, T-bills,  Gold Bonds, Listed Corporate Bonds, SDL, State Development Loan, government Securities, Govt Bonds, RBI regulated bonds, Safe Investments, Listed Corporate Bonds, Buy Govt Bonds, SEBI Regulated Bonds, Regulated Bonds, Best bond Investments, High Yield investments, Secured investments, Government safe investments',
    },
    '/signup': {
        title: `Sign-Up | ${brandName}`,
        metaTitle: `Sign-Up | ${brandName}`,
        metaDesc: `Welcome to the world of high return investment opportunities. Now diversify your portfolio beyond traditional assets and unlock great returns`,
        metaKeywords:
            'investment online, manage investments easily, low risk investments, safe investments, alternate investments, high returns, optimise risks, easy investments, investments in India, investment opportunities, alternative investments, high yield, alternate assets, compound your returns',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/app/signup`,
        metaIndex: 'noindex',
    },
    '/login': {
        title: `Login`,
        metaDesc: `Welcome to the world of high return investment opportunities. Now diversify your portfolio beyond traditional assets and unlock great returns`,
        metaKeywords:
            'investment online, manage investments easily, low risk investments, safe investments, alternate investments, high returns, optimise risks, easy investments, investments in India, investment opportunities, alternative investments, high yield, alternate assets, compound your returns',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/login`,
    },
    '/team': {
        title: `Meet the team | ${brandName}`,
        metaTitle: `Meet the team | ${brandName}`,
        metaDesc: `Meet the people behind ${brandName} (an alternative fixed-income investment platform) who bring great investment opportunities to clients via an easy-to-use digital platform. ${brandName} offers opportunities across various products that deliver high risk adjusted returns through its robust credit risk assessment process.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/team`,
    },
    '/credit-evaluation-process': {
        title: `Credit Assessment Process | Online Bond Platform | ${brandName}`,
        metaTitle: `Credit Assessment Process | Online Bond Platform | ${brandName}`,
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/credit-evaluation-process`,
        metaDesc: `Learn ${brandName}'s robust credit evaluation process undertaken on each investment opportunity. Invest in India’s leading Online Bond Platform and unlock the potential of listed bond investment products for a diversified portfolio. Earn high interest and reduce risk of your overall portfolio`,
        metaKeywords:
            'Credit assessment, Creditworthiness evaluation process, Investments, Listed Bonds, Credibility, Risk Assessment, Financial Health, credit analysis, credit risk, credit rating, risk management, risk mitigation.',
    },
    '/profile': {
        title: `Profile | ${brandName}`,
        metaDesc:
            'Investor Profile Details. The opportunities to build generational wealth have always remained with those with generational wealth. We are striving to bridge the long-existing accessibility gap to such high-hanging fruits of investment for the common investor.',
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/kyc': {
        title: `KYC`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/dashboard/investments': {
        title: 'Investments',
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/dashboard/investments/[investmentId]': {
        title: 'Investment Details',
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/create-new-account': {
        title: `Create New Account | ${brandName}`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/contact-us': {
        title: `Contact ${brandName}: Get Investment Support and Queries`,
        metaDesc: `Reach out to ${brandName} for investment support or inquiries. Connect with our team for further information and assistance.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/contact-us`,
    },
    '/careers': {
        title: `Careers | ${brandName}`,
        metaTitle: `Careers | ${brandName}`,
        metaDesc: `Join the exciting ${brandName} team and create a significant impact in the alternative fixed income industry. Several exciting career opportunities are available across all disciplines.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/careers`,
        metaIndex: 'noindex',
    },
    '/faq': {
        title: `Frequently Asked Questions | Online Bond Platform | ${brandName}`,
        metaTitle: `Frequently Asked Questions | Online Bond Platform | ${brandName}`,
        metaDesc: `Get your FAQs answered about listed corporate bonds, and know more about listed securities, listed bonds, T bills, G-secs, SDLs, government bonds, and gold bonds. Get your queries answered about Jiraaf online bond platform and get details on highly secured safe investment options available on the plaform Jiraaf is one of the best online bond platforms in India that is regulated by SEBI and provides investments that yield great returns with low to moderate risk.`,
        metaKeywords:
            'FAQs, frequently asked questions, what are Corporate Bonds, G-secs, T-Bills, Govt Bonds, regulated bonds, high return bonds, SEBI bonds, principal protection, secured investment, Jiraaf online bond platform, best bond investments, easy bond investing, long-term and short term bond investment options in India',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/faq`,
    },
    '/opportunity/[id]/fund-declaration': {
        title: `Fund Declaration`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/disclaimer': {
        title: `Disclaimer`,
        metaDesc: `${brandName}'s website is merely an intermediary or facilitator, and ${brandName} in no manner is engaged in either grant of loan or borrowing/fund raising from any participants on the website`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/opportunity/[id]/investment': {
        title: `Investment`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/investment/review': {
        title: `Review Document`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/investment/profile-details': {
        title: `Profile Details`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/investment/esign': {
        title: `Investment`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/investment/esign/onboarding': {
        title: `Investment`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/payment': {
        title: `Payment`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/select-payment': {
        title: `Select-Payment`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/opportunity/[id]/thankyou': {
        title: `Thank You`,
        metaDesc: `Welcome to the world of high return investment opportunities. Now diversify your portfolio beyond traditional assets and unlock great returns`,
        metaKeywords:
            'investment online, manage investments easily, low risk investments, safe investments, alternate investments, high returns, optimise risks, easy investments, investments in India, investment opportunities, alternative investments, high yield, alternate assets, compound your returns',
    },
    '/opportunity/[id]/payment/wallet': {
        title: `Thank You`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/terms-and-condition': {
        title: `Terms and Conditions: User Agreement | ${brandName}`,
        metaDesc: `Please read the Terms of Use carefully before using or registering on ${brandName}'s Website or accessing any material, information or services through the Website`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/terms-and-condition`,
    },
    '/disclaimer': {
        title: `Disclaimer: Investment Risks and Legal Info | ${brandName}`,
        metaDesc: `${brandName}'s website is merely an intermediary or facilitator, and ${brandName} in no manner is engaged in either grant of loan or borrowing/fund raising from any participants on the website`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/disclaimer`,
    },
    '/risk-disclosure': {
        title: `Risk Disclosure: Informed Investment Decisions | ${brandName}`,
        metaDesc: `Review ${brandName}’s risk disclosure policy to make informed investment decisions. Understand the risks tied to your portfolio.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/risk-disclosure`,
    },
    '/privacy-policy': {
        title: `Privacy Policy: Data Protection and Transparency | ${brandName}`,
        metaDesc: `Learn how ${brandName} safeguards your data. Explore our transparent privacy policy detailing how we collect, store, use and protect user information.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/privacy-policy`,
    },
    '/opportunity/[id]': {
        title: `${brandName} | Detailed overview of the opportunity`,
        metaDesc:
            'Detailed view of the opportunity to help you identify the key characteristics of the asset class and parties involved',
    },
    '/nri-disclaimer': {
        title: `NRI Disclaimer | ${brandName}`,
        metaDesc: `${brandName}'s website is merely an intermediary or facilitator, and Jiraaf in no manner is engaged in either grant of loan or borrowing/fund raising from any participants on the website`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/nri-disclaimer`,
    },
    '/investor-ifa-disclaimer': {
        title: `IFA Disclaimer | ${brandName}`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/investor-ifa-disclaimer`,
    },
    '/survey/[formid]/[entityid]': {
        title: `${brandName} | We value your feedback`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords: "Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details",
    },
    '#signupModal': {
        title: `${brandName} - Sign up for the ${brandName} experience`,
    },
    '#loginModal': {
        title: `${brandName} - Log in for the ${brandName} experience`,
    },
    '#VerifyOtpModal': {
        title: `Verify Otp`,
    },
    '/blogs': {
        title: `Blogs: Investment Insights into Bonds & more | ${brandName}`,
        metaDesc: `Welcome to the world of high return investment opportunities. Now diversify your portfolio beyond traditional assets and unlock great returns`,
        metaKeywords:
            'investment online, manage investments easily, low risk investments, safe investments, alternate investments, high returns, optimise risks, easy investments, investments in India, investment opportunities, alternative investments, high yield, alternate assets, compound your returns',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/blogs`,
    },
    '/kyc-nudge': {
        title: `Complete KYC | ${brandName}`
    },
    '/adminLogin': {
        title: `Admin Login`,
    },
    '/webinars': {
        title: `Webinars | ${brandName}`,
        metaDesc: `View some of the recent knowledge sessions and videos on ${brandName} and its products. Learn the benefits of adding alternate fixed income products to your investment portfolio.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/webinars`,
    },
    '/media': {
        title: `${brandName} in the Media: News, Updates, and Announcements`,
        metaDesc: `View latest media coverage on ${brandName} and its products in various publications.`,
        metaKeywords:
            'investment online, manage investments easily, low risk investments, safe investments, alternate investments, high returns, optimise risks, easy investments, investments in India, investment opportunities, alternative investments, high yield, alternate assets, compound your returns',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/media`,
    },
    '/bondfundamentals': {
        title: `Bond Investment Basics: Key Insights for Investors | ${brandName}`,
        metaDesc: `Learn the fundamentals of bond investments with ${brandName}. Explore key insights and tips to maximize your bond portfolio.`,
        metaKeywords:
            'Bonds, G-secs, T-Bills, Government Bonds, easy online bond investment, high return investments, High-interest rates, Guaranteed returns, principal protection, secured investment, Jiraaf online bond platform, best fixed return investments, long-term and short-term investment options in India',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/bondfundamentals`,
    },
    '/glossary': {
        title: `Investment Glossary: Definition of Key Financial Terms  | ${brandName}`,
        metaDesc: `Explore ${brandName}’s glossary of financial terms such as bonds, coupon rate, credit rating, YTM etc. Get all the easy-to-understand definitions here.`,
        metaKeywords:
            'financial ratios, finance 101, asset classes, debt, assets, liabilities, balance sheet, income statement, cash flows, bonds, compounding, simple interest, compound interest, return on capital, leverage, risk rating, credit rating, coupon, and IRR',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/glossary`,
    },
    '/grievance-redressal': {
        title: `Grievance Redressal | ${brandName}`,
        metaDesc: `${brandName}'s website is merely an intermediary or facilitator, and Jiraaf in no manner is engaged in either grant of loan or borrowing/fund raising from any participants on the website`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/grievance-redressal`,
    },
    '/investment-disclaimer': {
        title: `Investment Disclaimer | ${brandName}`,
        metaDesc: `Please read the Terms of Use carefully before using or registering on ${brandName}'s Website or accessing any material, information or services through the Website`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/investment-disclaimer`,
    },
    '/investor-charter': {
        title: `Investor Charter: Rights and Responsibilities | ${brandName}`,
        metaDesc: `Understand your rights and responsibilities as an investor. Explore ${brandName}'s Investor Charter for transparent investing.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/investor-charter`,
    },
    '/personal-information': {
        title: `Profile | ${brandName}`,
        metaDesc: `Investor Profile Details. The opportunities to build generational wealth have always remained with those with generational wealth. We are striving to bridge the long-existing accessibility gap to such high-hanging fruits of investment for the common investor.`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details, ',
    },
    '/pending-orders': {
        title: `Pending Orders`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/my-referrals': {
        title: `My Referrals`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/add-beneficiary': {
        title: `Investment Overview`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
    },
    '/questionnaire': {
        title: `Questionnaire`,
    },
    '/welcome': {
        title: `Welcome To ${brandName}`,
    },
    '/refer-and-earn': {
        title: `Refer & Earn`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    '/referral-program': {
        title: `Refer & Earn`,
        metaDesc: `${brandName} brings to you hand-picked investment opportunities across yield and tenure spectrums`,
        metaKeywords:
            'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details',
    },
    [routes.bonds.baseIsin]: {
        metaKeywords:
            'list of government bonds in india, corporate bonds list, list of corporate bonds in india,bond list,all bonds,list of bonds traded in india,list of all bonds,list of bonds issued in india',
    },
    [routes.bonds.base]: {
        title: `Bond Directory: List of all Bonds Traded in India`,
        metaDesc: `Explore the full list of bonds in India, from government to corporate bonds. Find all bonds issued and traded, perfect for investors seeking bond insights.`,
        metaKeywords:
            'list of government bonds in india, corporate bonds list, list of corporate bonds in india,bond list,all bonds,list of bonds traded in india,list of all bonds,list of bonds issued in india',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}${routes.bonds.base}`,
    },
    '/newsletters': {
        title: `Monthly Newsletters: Stay Updated with ${brandName}’s Latest Insights`,
        metaDesc: `Explore ${brandName}’s monthly newsletters to stay informed on the latest investment opportunities, market trends, and expert insights.`,
        metaKeywords: 'Dashboard, Investor, Investment opportunities, Yield, IRR, Returns, Opportunity details,',
        canonicalUrl: `${process.env.NEXT_PUBLIC_APP_URL}/newsletters`,
    },
};